import React from "react";
import Helmet from "react-helmet";
import { Jumbotron } from "../components/Jumbotron";
import SEO from "../components/SEO";
import "../assets/css/App.css";
import "../assets/css/index.css";
import Layout from "../components/Layout";

export default () => (
  <Layout>
    <SEO
      title="Commercial Air Conditioning"
      description="BBIS Commercial Heating Engineers London cover all aspects of heating &amp; hot water. Working with commercial heating systems &amp; domestic heating maintenance"
      keywords="Commercial Heating, Commercial Air Conditioning, Hertfordshire, London, Potters Bar, air conditioning london, air conditioning potters bar"
    />
    <Jumbotron imgClassName="img5" />
    <div class="white-segment text-left">
      <h1 class="heading-color">Air conditioning</h1>
      <p>
        BBIS Commercial heating ltd are able to provide your domestic or
        commercial premises with a complete installation of an air conditioning
        unit.
      </p>
      <p>
        Air conditioning will provide a healthy and comfortable environment to
        live and work in, by regulating humidity, temperature and the air
        quality. The indoor air is circulated and filtered which helps remove
        pollutants and allergens. Having an Air conditioning unit fitted, will
        be a sound investment for your business.
      </p>
      <p>
        Air conditioning units are becoming increasingly popular in residential
        properties here in the Uk, as they can improve sleep quality in the
        summer months and provide better air quality.
      </p>
      <p>
        We can advise you of the right air conditioning system to use, which
        will be the most energy efficient, and work most effectively for your
        property. There are various different types of systems such as ducted
        air conditioning, celling mounted cassette units and wall mounted air
        conditioning units.
      </p>
    </div>
    <div class="grey-segment text-left">
      <h1 class="heading-color">Servicing air conditioning</h1>
      <p>
        Just like your boiler it is important to have your air conditioning
        regularly serviced to help prolong the life of the system. We advise
        servicing every 12 months but it will depend on the workload of the
        system. Of course, if the system isn’t regularly maintained the
        components will be more likely to fail, the unit will be working harder
        to reach the required temperature, which will in turn increase your
        energy bill.
      </p>
      <p>
        The care of the systems filters and air vents is of high importance. The
        filters need to be removed and washed out as they help protect the other
        components, and filter the air by screening out allergens, dust and
        particles.{" "}
      </p>
      <p>
        Please contact us for a quote for air conditioning installations,
        repairs or servicing.{" "}
      </p>
      <p>
        <strong>
          <a href="tel:+448002289800">0800 2289800</a>
        </strong>
      </p>
    </div>
  </Layout>
);
